import { User } from '../../../models/User';
import { IAction } from '../../models/action.model';
import { FETCH, REMOVE, SAVE, UPDATE } from './actionTypes';

const defaultStore: User = {
  id: 0,
  username: '',
  userType: '',
  name: '',
  lastname: '',
  email: '',
  phone: '',
  dialCode: '',
  tenantClientId: 0,
  token: null,
  avatar: null,
};

export default function user(state: User = defaultStore, action: IAction) {
  switch (action.type) {
    case SAVE:
      return { ...action.payload };

    case FETCH:
      return {
        ...state,
      };

    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE:
      return { ...action.payload };
    default:
      return state;
  }
}
