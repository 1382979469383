/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import 'react-simple-hook-modal/dist/styles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'redux-react-hook';

import './App.scss';
import './assets/styles/global.scss';
import { TenantClient } from './models/TenantClient';
import UserAction from './redux/reducers/user/actions';
import Routes from './routes/routes';
import AuthService from './services/AuthService';
import TenantService from './services/TenantService';
import setUpInterceptor from './utils/interceptor';
import { CLIENT_DOMAIN } from 'config/constants';
import * as dotenv from 'dotenv';
import 'moment/locale/es';
import TenantAction from 'redux/reducers/tenant/actions';

const App = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const [loadingInitValues, setLoadingInitValues] = useState(true);
  const [tenantLoadError, setTenantLoadError] = useState(false);
  const authUser = useSelector((state: any) => state.user);
  dotenv.config({ path: '../.env' });

  setUpInterceptor(store);

  useEffect(() => {
    init();
  }, [dispatch, store]);

  const init = async () => {
    await verifyTenantAndUserSession();
    setLoadingInitValues(false);
  };

  const verifyTenantAndUserSession = async () => {
    const [tenantResponse, userResponse] = await Promise.all([
      TenantService.getByDomain(CLIENT_DOMAIN),
      AuthService.verifyUserSession(store),
    ]);

    //TODO: posiblemente optimizar la carga del tenant

    if (tenantResponse.success) {
      const tenant: TenantClient = tenantResponse.data;
      dispatch(TenantAction.save(tenantResponse.data));
      loadTenantWebConfiguration(tenant);
    } else {
      setTenantLoadError(true);
    }

    if (userResponse.success) {
      dispatch(UserAction.save(userResponse.data));
    } else {
      dispatch(UserAction.remove({}));
    }
  };

  const loadTenantWebConfiguration = (tenant: TenantClient) => {
    /* Favicon */
    const favicon: any =
      document.querySelector("link[rel='icon']") ||
      document.createElement('link');
    favicon.type = 'image/x-icon';
    favicon.rel = 'icon';
    favicon.href = tenant.webConfiguration.favicon;

    // const appleTouchIcon: any =
    //   document.querySelector("link[rel='apple-touch-icon']") ||
    //   document.createElement('link');
    // appleTouchIcon.rel = 'apple-touch-icon';
    // appleTouchIcon.href = 'logo192.png';

    // document.head.appendChild(appleTouchIcon);
    document.head.appendChild(favicon);

    document.title = tenant.webConfiguration.title;

    document.documentElement.style.setProperty(
      '--tenant_font_family',
      tenant.webConfiguration
        .fontFamily /*|| '"Inter", "Helvetica", "Arial", sans-serif'*/
    );
    document.documentElement.style.setProperty(
      '--tenant_main_background_color',
      tenant.webConfiguration.mainBackgroundColor /*|| '#C21000'*/
    );
    document.documentElement.style.setProperty(
      '--tenant_secondary_background_color',
      tenant.webConfiguration.secondaryBackgroundColor /*|| 'blue'*/
    );
    document.documentElement.style.setProperty(
      '--tenant_main_text_color',
      tenant.webConfiguration.mainTextColor /*|| 'white'*/
    );
    document.documentElement.style.setProperty(
      '--tenant_secondary_text_color',
      tenant.webConfiguration.secondaryTextColor /*|| 'black'*/
    );

    document.documentElement.style.setProperty(
      '--tenant_disabled_main_background_color',
      tenant.webConfiguration.disabledMainBackgroundColor /*|| '#707070'*/
    );
    document.documentElement.style.setProperty(
      '--tenant_disabled_secondary_background_color',
      tenant.webConfiguration.disabledSecondaryBackgroundColor /*|| 'gray'*/
    );
    document.documentElement.style.setProperty(
      '--tenant_disabled_main_text_color',
      tenant.webConfiguration.disabledMainTextColor /*|| 'white'*/
    );
    document.documentElement.style.setProperty(
      '--tenant_disabled_secondary_text_color',
      tenant.webConfiguration.disabledSecondaryTextColor /*|| 'black'*/
    );
  };

  return (
    <>
      {!loadingInitValues ? (
        tenantLoadError ? (
          <div>Tenant client error</div>
        ) : (
          <>
            <Routes auth={authUser} />
            <ToastContainer />
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
