//resources
import React from 'react';
import { Modal, ModalTransition } from "react-simple-hook-modal";

//css
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Text from '../Text/Text';
import {useDispatch} from "redux-react-hook";
import {useSelector} from "react-redux";
import AlertActions from "../../redux/reducers/alertMessage/actions";
import {Button} from "../ButtonModal";
import 'react-simple-hook-modal/dist/styles.css';

import './AlertMessageModal.scss';

export default function AlertMessageModal () {
  const dispatch = useDispatch();
  const alertMessage = useSelector((state:any) => state.alert);


  const closeModal = async () => {
    if(typeof alertMessage?.payload?.beforeCloseModal === 'function') {
      alertMessage.payload.beforeCloseModal();
    }

    dispatch(AlertActions.remove());
  };

  const doneActionButton = async () => {
    if(typeof alertMessage?.payload?.beforeActionButton === 'function') {
      alertMessage.payload.beforeActionButton();
    }

    dispatch(AlertActions.remove());
  };

  return (
    <>
      <Modal
        id="alert-message-modal-alert"
        isOpen={alertMessage.open}
        transition={ModalTransition.SCALE}
        modalClassName="general_modal_main_alert"
      >
        <CloseIcon className='general_modal_close_alert' onClick={closeModal} style={{zIndex: '2000'}}/>
        {/* Icon */}
        <div className='general_model_top_content_alert' style={{marginTop: '10px'}}>

          { alertMessage?.icon === 'danger' && (<ErrorOutlineIcon color="error" />) }
          { alertMessage?.icon === 'success' && (<CheckCircleOutlineIcon color="success" />) }

          {/* Content text */}
          {
              typeof alertMessage?.payload?.message === 'string'
                ? <Text className='general_modal_text_alert'  text={alertMessage?.payload?.message} />
                : alertMessage?.payload?.message
          }
        </div>

        {
          alertMessage?.payload?.buttonText
              ? <Button className='general_modal_button_alert'  type="primary" text={alertMessage.payload.buttonText}  onClick={doneActionButton}/>
              : ''
        }
      </Modal>
    </>
  );
}

