import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { StoreContext } from 'redux-react-hook';

import App from './App';
import './App.scss';
import LoaderLocal from './components/Atoms/LoaderLocal/LoaderLocal';
import { makeStore } from './redux/reducers';
import * as serviceWorker from './serviceWorker';

const { persistor, store } = makeStore();

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <PersistGate loading={<LoaderLocal />} persistor={persistor}>
      <Provider store={store}>
        <App />
      </Provider>
    </PersistGate>
  </StoreContext.Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
