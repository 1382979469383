import { TenantClient } from '../models/TenantClient';
import api from '../utils/axios';
import { getUrlByS3Key } from '../utils/helpers';

const TenantService = {
  getByDomain: async (domain: string): Promise<any> => {
    try {
      const response = await api.post(`/tenant-client/find-by-domain`, {
        domain,
      });
      const rawData = response?.data?.data;

      return {
        success: true,
        data: {
          id: rawData.id,
          name: rawData.name,
          requestPrefix: rawData.requestPrefix,
          defaultActivationPrice: parseFloat(
            `${rawData.defaultActivationPrice}`
          ),
          defaultTaxRate: parseFloat(`${rawData.defaultTaxRate}`),
          currency: {
            code: rawData.currency.code,
            name: rawData.currency.name,
            symbol: rawData.currency.symbol,
          },
          webConfiguration: {
            title: rawData.additionalData.title || '',
            logo: getUrlByS3Key(rawData.additionalData.logo || ''),
            favicon: getUrlByS3Key(rawData.additionalData.favicon || ''),
            fontFamily: rawData.additionalData.font_family || '',
            mainBackgroundColor:
              rawData.additionalData.main_background_color || '',
            secondaryBackgroundColor:
              rawData.additionalData.secondary_background_color || '',
            mainTextColor: rawData.additionalData.main_text_color || '',
            secondaryTextColor:
              rawData.additionalData.secondary_text_color || '',
            disabledMainBackgroundColor:
              rawData.additionalData.disabled_main_background_color || '',
            disabledSecondaryBackgroundColor:
              rawData.additionalData.disabled_secondary_background_color || '',
            disabledMainTextColor:
              rawData.additionalData.disabled_main_text_color || '',
            disabledSecondaryTextColor:
              rawData.additionalData.disabled_secondary_text_color || '',
          },
        } as TenantClient,
      };
    } catch (err) {
      console.log(err);
    }
  },
};

export default TenantService;
