import { Container } from "@material-ui/core";

import './Text.scss';

interface TextInterface {
  className?: string;
  disabled?: boolean;
  large?: boolean;
  text: string | React.ReactNode;
};

const Text = ({
  className = '',
  disabled = false,
  large = false,
  text,
  ...rest
}: TextInterface) => {
  if (className) className = ` ${className}`;

  return large ? (
    <p className={`text__main${disabled ? ' text__main--disabled' : ''}${className}`} {...rest}>{text}</p>
  ) : (
   <Container maxWidth="sm">
      <p className={`text__main${disabled ? ' text__main--disabled' : ''}${className}`} {...rest}>{text}</p>
   </Container>
  );
};

export default Text;
