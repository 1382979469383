import { BACKEND_APP_API_URL } from '../config/constants';

export const hasErrors = (errors: any): any => {
  return Object.keys(errors).some((key) => {
    const value = errors[key];

    if (value && typeof value === 'object') {
      return hasErrors(value);
    }
    return typeof value !== 'undefined';
  });
};

export const generateRequestNumber = (
  prefix: string,
  number: number,
  paddingLength: number = 15
): string => {
  const numberString = number.toString();
  const padding = '0'.repeat(paddingLength - numberString.length);
  return `${prefix}${padding}${numberString}`;
};

export const getUrlByS3Key = (s3Key: string) => {
  return s3Key
    ? `${BACKEND_APP_API_URL}/file/download/${encodeURIComponent(s3Key)}`
    : '';
};

export const generateUniqueId = () => {
  const timestamp = Date.now().toString(36); // Obtener el timestamp actual y convertirlo a base 36
  const randomNum = Math.random().toString(36).substring(2, 5); // Generar un número aleatorio y convertirlo a base 36
  const uniqueId = timestamp + randomNum; // Combinar el timestamp y el número aleatorio

  return uniqueId;
};

export const getMoneyFormat = (
  number: number | string,
  moneySymbol: string = ''
) => {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }
  const partes = number.toFixed(2).toString().split('.');
  const parteEnteraFormateada = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const finalNumber = parteEnteraFormateada + '.' + partes[1];

  if (moneySymbol) {
    return `${moneySymbol} ${finalNumber}`;
  }

  return finalNumber;
};
