import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, ...children }: any): JSX.Element {
  const user = useSelector((state: any) => state.user);

  if (user.id && user.id !== 0) {
    return <Route {...children} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to="/sign-in" />;
}

export default PrivateRoute;
