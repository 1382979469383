import { ADD_ALERT, REMOVE_ALERT, ADD_ALERT_SUCCESS, ADD_ALERT_DANGER } from './actionTypes';

export interface IAlertMessagePayload {
  message: string | JSX.Element,
  buttonText?: string,
  beforeActionButton?: () => void,
  beforeCloseModal?: () => void,
}

const addAlert = (payload: IAlertMessagePayload) => {
    return {
      type: ADD_ALERT,
      payload
    };
  };

const success = (payload: IAlertMessagePayload) => {
  return {
    type: ADD_ALERT_SUCCESS,
    payload
  };
};

const danger = (payload: IAlertMessagePayload) => {
  return {
    type: ADD_ALERT_DANGER,
    payload
  };
};
  
const remove = () => {
    return {
      type: REMOVE_ALERT
    };
  };


const AlertActions = {
  addAlert,
  success,
  danger,
  remove
}

export default AlertActions;
