import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import './LoaderLocal.scss';

const LoaderLocal = () => {
  return (
    <div className="loader-local__container">
      <Box className="loader-local__main" sx={{ position: 'relative' }}>
        <CircularProgress
          sx={{ color: '#C21000' }}
          disableShrink
          value={10}
          size={40}
          thickness={4}
        />
      </Box>
    </div>
  );
};

export default LoaderLocal;
