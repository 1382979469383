import { User } from '../models/User';
import api from '../utils/axios';
import { UserLogin } from './interfaces/AuthInterface';

const AuthService = {
  verifyUserSession: async (store: any): Promise<any> => {
    if (!store.getState().user.token) {
      return {
        success: false,
        error: "Don't have a token",
      };
    }

    try {
      const response = await api.get(`/user/auth`);
      const responseData = response?.data?.data;

      return {
        success: true,
        data: {
          id: responseData.id,
          username: responseData.username,
          userType: responseData.userType,
          name: responseData.name,
          lastname: responseData.lastname,
          email: responseData.email,
          phone: responseData.phone,
          dialCode: responseData.dialCode,
          tenantClientId: responseData.tenantClientId,
          token: responseData.sessionToken || null,
          avatar: responseData.avatar || null,
        } as User,
      };
    } catch (err) {
      console.log(err);
      return {
        success: false,
        data: err?.response?.data.data,
      };
    }
  },
  login: async (userData: UserLogin): Promise<any> => {
    try {
      const response = await api.post(`/user/login`, userData);
      const responseData = response?.data?.data;
      return {
        success: true,
        data: {
          id: responseData.id,
          username: responseData.username,
          userType: responseData.userType,
          name: responseData.name,
          lastname: responseData.lastname,
          email: responseData.email,
          phone: responseData.phone,
          dialCode: responseData.dialCode,
          tenantClientId: responseData.tenantClientId,
          token: responseData.sessionToken || null,
          avatar: responseData.avatar || null,
        } as User,
      };
    } catch (err) {
      console.log(err);
      return {
        success: false,
        data: err?.response?.data.data,
      };
    }
  },
  logout: async (token: string): Promise<any> => {
    try {
      const response = await api.post(
        `/user/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return {
        success: true,
        data: response.data,
      };
    } catch (err) {
      console.log(err);
      return {
        success: false,
        data: err?.response?.data.data,
      };
    }
  },
};

export default AuthService;
