import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';

function PublicRoute({ component: Component, ...children }: any): JSX.Element {
  const user = useSelector((state: any) => state.user);

  if (user.id === undefined || user.id === 0) {
    return <Route {...children} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to="/pbi/share-report-2024" />;
}

export default PublicRoute;
