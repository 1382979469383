import axios, {AxiosInstance} from "axios";
import {BACKEND_APP_API_URL, CLIENT_DOMAIN} from "../config/constants";

const instance: AxiosInstance = axios.create({
    baseURL: BACKEND_APP_API_URL,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "tenant_client_domain":CLIENT_DOMAIN
    },
});

export default instance;