import alertMessageReducer from './alertMessage/reducer';
import tenantReducer from './tenant/reducer';
import userReducer from './user/reducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  user: userReducer,
  alert: alertMessageReducer,
  tenant: tenantReducer,
});

export default rootReducer;
