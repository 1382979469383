import { TenantClient } from '../../../models/TenantClient';
import { IAction } from '../../models/action.model';
import { FETCH, REMOVE, SAVE, UPDATE } from './actionTypes';

const defaultStore: TenantClient = {
  id: 0,
  name: '',
  requestPrefix: '',
  defaultActivationPrice: 0,
  defaultTaxRate: 0,
  currency: {
    code: '',
    name: '',
    symbol: '',
  },
  webConfiguration: {
    title: '',
    logo: '',
    favicon: '',
    fontFamily: '',
    mainBackgroundColor: '',
    secondaryBackgroundColor: '',
    mainTextColor: '',
    secondaryTextColor: '',
    disabledMainBackgroundColor: '',
    disabledSecondaryBackgroundColor: '',
    disabledMainTextColor: '',
    disabledSecondaryTextColor: '',
  },
};

export default function tenant(
  state: TenantClient = defaultStore,
  action: IAction
) {
  switch (action.type) {
    case SAVE:
      return {
        ...action.payload,
      };

    case FETCH:
      return {
        ...state,
      };

    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE:
      return {
        ...state,
        ...defaultStore,
      };

    default:
      return state;
  }
}
