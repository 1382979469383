import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { ModalProvider } from 'react-simple-hook-modal';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Route from './components/Route';
import AlertMessageModal from 'components/AlertMessageModal/AlertMessageModal';
import PropTypes from 'prop-types';

export default function Routes({ auth }: any) {
  //Pages
  const Landing = lazy(() => import('../scenes/landing'));
  const SignIn = lazy(() => import('../scenes/SignIn'));
  const SignUp = lazy(() => import('../scenes/SingUp'));
  const ActivateAccount = lazy(() => import('../scenes/ActivateAccount'));
  const ResetPassword = lazy(() => import('../scenes/ResetPassword'));
  const RequestActivation = lazy(() => import('../scenes/RequestActivation'));
  const TurnActivation = lazy(() => import('../scenes/TurnActivation'));
  const HistoryActivation = lazy(() => import('../scenes/History'));
  const ActivationDetail = lazy(() => import('../scenes/ActivationDetail'));
  const ForgottenPassword = lazy(() => import('../scenes/ForgottenPassword'));
  const PdfViewer = lazy(() => import('../scenes/PdfViewer'));
  const ExcelViewer = lazy(() => import('../scenes/ExcelViewer'));
  const Home = lazy(() => import('../scenes/Home'));
  const NotFound = lazy(() => import('../scenes/NotFound'));

  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <ModalProvider backdropClassName="modal-provider__main">
          <AlertMessageModal />
          <Switch>
            <Route exact path="/">
              {auth.id === undefined || auth.id === 0 ? (
                <Redirect to="/sign-in" />
              ) : (
                <Redirect to="/pbi/share-report-2024" />
              )}
            </Route>
            {/*<PrivateRoute*/}
            {/*  exact*/}
            {/*  path="/turn-activation"*/}
            {/*  component={TurnActivation}*/}
            {/*/>*/}
            <PublicRoute exact path="/sign-in" component={SignIn} />
            <PublicRoute exact path="/sign-up" component={SignUp} />
            <PublicRoute
              exact
              path="/forgotten-password"
              component={ForgottenPassword}
            />
            <PublicRoute
              exact
              path="/activate-account/:token"
              component={ActivateAccount}
            />
            <PublicRoute
              exact
              path="/reset-password/:token"
              component={ResetPassword}
            />
            {/*<PrivateRoute*/}
            {/*  exact*/}
            {/*  path="/history-activation/detail/:activationId"*/}
            {/*  component={ActivationDetail}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
            {/*  exact*/}
            {/*  path="/history-activation"*/}
            {/*  component={HistoryActivation}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
            {/*  exact*/}
            {/*  path="/request-activation"*/}
            {/*  component={RequestActivation}*/}
            {/*/>*/}

            <PrivateRoute exact path="/pbi/:report" component={Home} />
            <PrivateRoute exact path="/pdf-viewer/:key" component={PdfViewer} />
            <PrivateRoute
              exact
              path="/excel-viewer/:key"
              component={ExcelViewer}
            />

            <Route path="*" component={NotFound} />
          </Switch>
        </ModalProvider>
      </Suspense>
    </BrowserRouter>
  );
}

Routes.propTypes = {
  auth: PropTypes.object.isRequired,
};
